export const eventTableau = [
  {
    id: "E0013",
    place: "Rockstore, Montpellier",
    date: "19.04.2024",
    lineUp: "Teho | Romain Garcia",
    lien:"https://www.facebook.com/events/1568938580532404",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Rockstore_2024.jpg"
    }`,
  },
  {
    id: "E0012",
    place: "Rockstore, Montpellier",
    date: "26.03.2022",
    lineUp: "Teho | Aalson | Ceas",
    lien:"https://www.facebook.com/events/338712547672571",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Rockstore_2022.jpg"
    }`,
  },
  {
    id: "E0011",
    place: "Antirouille, Montpellier",
    date: "22.02.2020",
    lineUp: "Teho | Aalson | Ceas",
    lien:"https://www.facebook.com/events/1069772303414566/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-2020.jpg"
    }`,
  },
  {
    id: "E0010",
    place: "Salle Municipale, Apt",
    date: "27.04.2019",
    lineUp: "Teho | Ceas | Aalson",
    lien:"https://www.facebook.com/events/2258690997557347/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Apt.jpg"
    }`,
  },
  {
    id: "E0009",
    place: "BT59, Bordeaux",
    date: "20.04.2019",
    lineUp: "Teho | Ceas | Aalson", 
    lien:"https://www.facebook.com/events/276369029692472/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Bordeaux.jpg"
    }`,
  },
  {
    id: "E0008",
    place: "Connexion Live, Toulouse",
    date: "19.04.2019",
    lineUp: "Teho | Ceas | Aalson",
    lien:"https://www.facebook.com/events/276369029692472/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Toulouse.jpg"
    }`,
  },
  {
    id: "E0007",
    place: "Bellevilloise, Paris",
    date: "06.04.2019",
    lineUp: "Teho | Ceas | Üm & Tisha",
    lien:"https://www.facebook.com/events/1501050540038631/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Paris.jpg"
    }`,
  },
  {
    id: "E0006",
    place: "Jardin de la Source, Piolenc",
    date: "22.03.2019",
    lineUp: "Teho | Ceas",
    lien:"https://www.facebook.com/events/1962496507202077/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Piolenc.jpg"
    }`,
  },
  {
    id: "E0005",
    place: "Ampérage, Grenoble",
    date: "16.03.2019",
    lineUp: "Teho | Ceas | Aalson",
    lien:"https://www.facebook.com/events/371710680071849/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Grenoble.jpg"
    }`,
  },
  {
    id: "E0004",
    place: "Antirouille, Montpellier",
    date: "23.02.2019",
    lineUp: "Teho | Ceas | Aalson",
    lien:"https://www.facebook.com/events/2289125538041139/",
    banner: `${
      process.env.PUBLIC_URL +
      "/img/event/Banners-Events-Labo_T-Montpellier.jpg"
    }`,
  },
  {
    id: "E0003",
    place: "La Moba, Bagnols sur Cèze",
    date: "16.02.2019",
    lineUp: "Teho | Ceas | Aalson",
    lien:"https://www.facebook.com/events/314638449376718/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Bagnols.jpg"
    }`,
  },
  {
    id: "E0002",
    place: "Kwartz Club, Nice",
    date: "02.02.2019",
    lineUp: "Teho | Ceas | Aalson",
    lien:"https://www.facebook.com/events/288388942018771/",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-Nice.jpg"
    }`,
  },
  {
    id: "E0001",
    place: "Antirouille, Montpellier",
    lien:"https://www.facebook.com/events/162292184355077/",
    date: "10.02.2018",
    lineUp: "Teho | Aalson | Max TenRoM | Mashk",
    banner: `${
      process.env.PUBLIC_URL + "/img/event/Banners-Events-Labo_T-1er.jpg"
    }`,
  },
];
